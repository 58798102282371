import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import * as styles from '../components/error.module.css'

class errorIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className={styles.error}>
          <div>
            <h1>404</h1>
            <p>Sorry. There is nothing here. Let’s go to a better place.</p>
            <Link className={styles.button} to="/products">Products</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default errorIndex
